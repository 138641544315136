import { Instance, types } from 'mobx-state-tree';

export type FrozenData = Record<string, { id: string; name: string }>;
type FrozenAuthor = Record<string, { name: string; authorTag: string }>;
type FrozenDictionary = Record<string, { id: string; name: string }>;
type PluginGroupPerTab = Record<string, { filterIds: string[]; list: string[] }>;
export type PluginPricing = { id: string; data: string[] };
export type PluginPricingRecord = Record<string, PluginPricing[]>;
export type PluginComplexPricing = { id: string; prices: string[]; asset: string };
export type PluginComplexPricingRecord = Record<string, PluginComplexPricing[]>;
export type FrozenPlugin = Record<
    string,
    {
        type: 'plugin' | 'asset';
        name: string;
        urls: { details?: string; download?: string; evaluation?: string };
        author: string;
        tags: string[];
        related: string[];
        ctas: ('details_page' | 'goto_trial_page' | 'open_launcher')[];
        media: { url: string; i18nAria: string }[];
        pricing?: PluginPricingRecord;
        complexPricing?: PluginComplexPricingRecord;
    }
>;

const SupportedPlatformsModel = types.model('SupportedPlatformsModel', {
    wwise_versions: types.frozen<FrozenData>({}),
    platforms: types.frozen<FrozenData>({}),
    integrations: types.frozen<FrozenData>({}),
    authors: types.frozen<FrozenAuthor>({}),
    authorsFilter: types.frozen<FrozenDictionary>({}),
    pluginTags: types.frozen<FrozenDictionary>({}),
    effectTypes: types.frozen<FrozenDictionary>({}),
    pluginGroups: types.frozen<PluginGroupPerTab>({}),
    plugins: types.frozen<FrozenPlugin>({}),
    requires_dev_status_confirmation: types.frozen<string[]>([]),
    defaults: types.frozen<{
        platforms: string[];
        integrations: string[];
        plugins: string[];
        wwise_version: string;
    }>({
        platforms: [],
        integrations: [],
        plugins: [],
        wwise_version: '',
    }),
    partially_supported: types.frozen<Record<string, string[][]>>({}),
    not_supported: types.frozen<Record<string, string[][]>>({}),
});
export type TypeSupportedPlatformModel = Instance<typeof SupportedPlatformsModel>;

export default SupportedPlatformsModel;
