import { SupportedLocale } from '../../i18n/supported-locales';
import { ProtectedFeature } from '../../utils/feature-flags';
import { MODULES } from '../modules';

export type ModuleId = keyof typeof MODULES;
export type RouteId<M extends ModuleId = ModuleId> = keyof typeof MODULES[M]['routes'];

export type TargetType<M extends ModuleId> = {
    [C in M]: {
        module: C;
        subModule: RouteId<C>;
        params?: {};
        locationSearch?: string;
    };
}[M];

export interface TypeButton<M extends ModuleId = ModuleId> {
    label: string;
    target: TargetType<M>;
}

export interface ModuleFunction<I = string> {
    id: I;
    name: string;
    i18nId: string;
    displayInMenu?: boolean;
    displayInFooter?: boolean;
    supportedLocales?: SupportedLocale[];

    action?: (param?: any) => void;
    redirectTo?: string;
}

export interface ModuleRoute<I = string> {
    id: I;
    IconComponent?: () => JSX.Element;
    linkClass?: string;
    i18nId: string;
    displayInMenu?: boolean;
    displayInFooter?: boolean;
    supportedLocales?: SupportedLocale[];

    action?: (param?: any) => void;
    onLoadAction?: () => void;
    path: string;
    hardPath?: string | Record<SupportedLocale, string>;
    redirectTo?: string;
    component?: React.LazyExoticComponent<any>;
    privateMode?: boolean;
    postSignInUpTarget?: string;
    protectedFeature?: ProtectedFeature;
}

export interface Module<M extends ModuleId = ModuleId> {
    id: M;
    i18nId?: string;
    routes: { [R in RouteId<M>]: ModuleRoute<R> };
    defaultRoute: ModuleRoute<RouteId<M>>;
    displayInMenu?: boolean;
    displayInFooter?: boolean;
}

export function typedRoutes<R extends Record<string, ModuleRoute<any> | ModuleFunction<any>>>(
    routes: R
): R {
    return routes;
}
