import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/general`;

export const PATHS = {
    GENERAL: {
        path: `${moduleRoot}`,
    },
    FEATURE_FLAGS: {
        path: `${moduleRoot}/feature-flags`,
    },
    REGISTER_PROJECT: {
        path: `${moduleRoot}/register_project`,
        hardPath: HARD_PATHS.GENERAL.REGISTER_PROJECT,
    },
    CART: {
        path: `${moduleRoot}/cart`,
        hardPath: HARD_PATHS.GENERAL.CART,
    },
    FCRM_SEARCH: {
        path: `${moduleRoot}/search`,
        hardPath: HARD_PATHS.GENERAL.FCRM_SEARCH,
    },
    CREATE_ACCOUNT: {
        path: '/create-new-account',
    },
    CREATE_BLOG_ACCOUNT: {
        path: `/settings/account-settings/communications`,
        hardPath: HARD_PATHS.GENERAL.CREATE_BLOG_ACCOUNT,
    },
    FCRM_MY_PROFILE: {
        path: `${moduleRoot}/profile`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_PROFILE,
    },
    FCRM_MY_MESSAGES: {
        path: `${moduleRoot}/profile/my-messages`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_MESSAGES,
    },
    FCRM_MY_DETAILS: {
        path: `${moduleRoot}/profile/details`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_DETAILS,
    },
    PROJECT_INVITATION: {
        path: `/project-invitation`,
    },
    FCRM_MY_PROJECTS: {
        path: `${moduleRoot}/register-projects`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_PROJECTS,
    },
    FCRM_MY_COURSES: {
        path: `${moduleRoot}/profile/my-courses`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_COURSES,
    },
    FCRM_MY_ORGANIZATIONS: {
        path: `${moduleRoot}/profile/my-organizations`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_ORGANIZATIONS,
    },
    FCRM_MY_COMPANY: {
        path: `${moduleRoot}/profile/my-company`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_COMPANY,
    },
    FCRM_MY_SCHOOL: {
        path: `${moduleRoot}/profile/my-school`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_SCHOOL,
    },
    FCRM_MY_NOTIFICATIONS: {
        path: `${moduleRoot}/profile/notifications`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_NOTIFICATIONS,
    },
    DOWNLOAD_LAUNCHER: {
        path: '/download',
    },
    FCRM_REGISTERED_PROJECTS: {
        path: `${moduleRoot}/profile/my-projects`,
        hardPath: HARD_PATHS.GENERAL.FCRM_REGISTERED_PROJECTS,
    },
    FCRM_PRICING: {
        path: `${moduleRoot}/pricing`,
        hardPath: HARD_PATHS.GENERAL.FCRM_PRICING,
    },
    ROYALTY_LICENSING: {
        path: `${moduleRoot}/licensing/faq/royaltyLicensing`,
    },
    GAAS_LICENSING: {
        path: `${moduleRoot}/licensing/faq/gaasLicensing`,
    },
    TRIAL_LICENSING: {
        path: `${moduleRoot}/licensing/faq/trialLicensing`,
    },
    COMMERCIAL_LICENSING: {
        path: `${moduleRoot}/licensing/faq/commercialLicensing`,
    },
    WWISE_COMMERCIAL_LICENSING: {
        path: `${moduleRoot}/licensing/faq/wwiseCommercialLicensing`,
    },
    ACADEMIC_LICENSING: {
        path: `${moduleRoot}/licensing/faq/academicLicensing`,
    },
    STRATA_LICENSING: {
        path: `${moduleRoot}/licensing/faq/strataLicenses`,
    },
    FCRM_MY_SUPPORT_TICKETS: {
        path: `${moduleRoot}/support/my-tickets`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_SUPPORT_TICKETS,
    },
    FCRM_MY_LICENSES: {
        path: `${moduleRoot}/account/licenses/`,
        hardPath: HARD_PATHS.GENERAL.FCRM_MY_LICENSES,
    },
    FCRM_MY_STORE: {
        path: `${moduleRoot}/account/subscriptions/`,
        hardPath: HARD_PATHS.STORE.STRATA,
    },
    FCRM_IN_GAME_CREDITS: {
        path: `${moduleRoot}/in-game-credits`,
        hardPath: HARD_PATHS.GENERAL.FCRM_IN_GAME_CREDITS,
    },
    WWISE_TOUR_REGISTER_TO_ATTEND: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.WWISE_TOUR_REGISTER_TO_ATTEND,
    },
    SUBMIT_YOUR_TALK_WWISE_TOUR: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.SUBMIT_YOUR_TALK_WWISE_TOUR,
    },
    INTERACTIVE_MUSIC_SYMPOSIUM_REGISTER_TO_ATTEND: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.INTERACTIVE_MUSIC_SYMPOSIUM_REGISTER_TO_ATTEND,
    },
    SUBMIT_YOUR_TALK_INTERACTIVE_MUSIC_SYMPOSIUM: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.SUBMIT_YOUR_TALK_INTERACTIVE_MUSIC_SYMPOSIUM,
    },
    WWWOE_REGISTER_TO_ATTEND: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.WWWOE_REGISTER_TO_ATTEND,
    },
    RESOURCES_VIDEOS: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.RESOURCES_VIDEOS,
    },
    RESOURCES_SAMPLES: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.RESOURCES_SAMPLES,
    },
    LINUX_AUTOMOTIVE: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.LINUX_AUTOMOTIVE,
    },
    STRATA_LICENSE_AGREEMENT: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.STRATA_LICENSE_AGREEMENT,
    },
    STRATA_SUBSCRIPTION_AGREEMENT: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.STRATA_SUBSCRIPTION_AGREEMENT,
    },
    COMMUNITY_PARTNER_MARKETING_GUIDELINE: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.GENERAL.COMMUNITY_PARTNER_MARKETING_GUIDELINE,
    },
    SUBSCRIBE_GENERAL_YOUTUBE: {
        path: `${moduleRoot}/directory/users`,
        hardPath: HARD_PATHS.GENERAL.SUBSCRIBE_GENERAL_YOUTUBE,
    },
    DESCRIPTION_TESTER: {
        path: `${moduleRoot}/description-tester`,
    },
    FOUR_OH_FOUR: {
        path: `${moduleRoot}/four-oh-four`,
    },
};
