import { lazy } from 'react';

import { typedRoutes } from './utils/module-interfaces';

import { AkLogo, AkFooterLogo } from '../assets/icons/svgs';
import ApplicationStoreInstance from '../stores/application-store';

import { HEADER_TOOLS } from '../constants';

import { PATHS } from './soft-paths/home';

const { SIGN_IN } = HEADER_TOOLS;

export const ROUTES = typedRoutes({
    HOME: {
        ...PATHS.HOME,
        id: 'HOME',
        name: 'Home',
        IconComponent: AkLogo,
        linkClass: 'menu-home__header-logo',
        i18nId: 'home:module.home.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/home/home')),
    },
    HOME_LOGIN: {
        ...PATHS.HOME_LOGIN,
        id: 'HOME_LOGIN',
        name: 'Home + Login',
        linkClass: 'menu-home__header-logo',
        i18nId: 'home:module.home.menu-label',
        displayInMenu: false,
        onLoadAction: () => {
            ApplicationStoreInstance.showToolInHeader(SIGN_IN);
        },
        component: lazy(() => import('../components/modules/home/home')),
    },
    FOOTER_HOME: {
        ...PATHS.FOOTER_HOME,
        id: 'FOOTER_HOME',
        name: 'Footer Home',
        IconComponent: AkFooterLogo,
        linkClass: 'menu-home__footer-logo',
        i18nId: 'home:module.home.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/home/home')),
    },
});

export const DEFAULT_ROUTE = ROUTES.HOME;
