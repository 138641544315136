import { cloneDeep, find, forEach } from 'lodash';
import { EditableFieldProps } from './field-factory';

export const extractUiData = (generatorFields: EditableFieldProps[], clusterData: any) => {
    const clusterClone = cloneDeep(clusterData);
    if (generatorFields) {
        forEach(clusterClone, (cd, cdId) => {
            const gf = find(generatorFields, (f) => f.id === cdId);
            if (gf?.fieldType === 'TextFieldDropDown') {
                const optionListItem = find(
                    gf?.optionList,
                    (item: { key: string; label: string }) => item.key === cd
                ) as EditableFieldProps;
                clusterClone[`${cdId}Label`] = optionListItem?.value;
            }
        });
    }
    return clusterClone;
};
