import { lazy } from 'react';

import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/learning';

export const ROUTES = typedRoutes({
    LEARNING: {
        ...PATHS.LEARNING,
        id: 'LEARNING',
        name: 'Learning',
        i18nId: 'learning:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/learning/learning')),
    },
    LEARN_WWISE: {
        ...PATHS.LEARN_WWISE,
        id: 'LEARN_WWISE',
        name: 'Learn Wwise',
        i18nId: 'learning:learn-wwise.title',
        component: lazy(() => import('../components/modules/learning/learn-wwise')),
    },
    LEARN_WWISE_MORE_INFO: {
        ...PATHS.LEARN_WWISE_MORE_INFO,
        id: 'LEARN_WWISE_MORE_INFO',
        name: 'Learn Wwise more info',
        i18nId: 'learning:learn-wwise.title',
        displayInMenu: false,
        component: lazy(
            () =>
                import(
                    '../components/modules/learning/legos/learn-wwise-more-info/learn-wwise-more-info'
                )
        ),
    },
    TEACH_WWISE: {
        ...PATHS.TEACH_WWISE,
        id: 'TEACH_WWISE',
        name: 'Teach Wwise',
        i18nId: 'learning:teach-wwise.title',
        component: lazy(() => import('../components/modules/learning/teach-wwise')),
    },
    TRAINING: {
        ...PATHS.TRAINING,
        id: 'TRAINING',
        name: 'Training',
        i18nId: 'learning:training.title',
        component: lazy(() => import('../components/modules/learning/training')),
    },
    CREATE_SCHOOL_ACCOUNT: {
        ...PATHS.CREATE_SCHOOL_ACCOUNT,
        id: 'CREATE_SCHOOL_ACCOUNT',
        name: 'Create School Account',
        i18nId: 'learning:create-school-account.title',
        displayInMenu: false,
        component: lazy(
            () =>
                import(
                    '../components/modules/learning/legos/create-school-account/create-school-account'
                )
        ),
    },
    CERTIFICATIONS: {
        ...PATHS.CERTIFICATIONS,
        id: 'CERTIFICATIONS',
        name: 'Certifications',
        i18nId: 'learning:certifications.title',
        component: lazy(() => import('../components/modules/learning/certifications')),
    },
    CERTIFICATION_MORE_INFO: {
        ...PATHS.CERTIFICATION_MORE_INFO,
        id: 'CERTIFICATION_MORE_INFO',
        name: 'Certification More Info',
        i18nId: 'learning:certifications.title',
        displayInMenu: false,
        component: lazy(
            () =>
                import(
                    '../components/modules/learning/legos/certification-more-info/certification-more-info'
                )
        ),
    },
    VIDEOS: {
        ...PATHS.VIDEOS,
        id: 'VIDEOS',
        name: 'Videos',
        i18nId: 'learning:videos.title',
    },
    SAMPLES: {
        ...PATHS.SAMPLES,
        id: 'SAMPLES',
        name: 'Samples',
        i18nId: 'learning:samples.menu-label',
        component: lazy(() => import('../components/modules/learning/legos/samples/samples')),
    },
    SAMPLE: {
        ...PATHS.SAMPLE,
        id: 'SAMPLE',
        name: 'Sample Details',
        i18nId: 'store:module.strata.menu-label',
        displayInMenu: false,
        component: lazy(
            () => import('../components/modules/learning/legos/samples/sample-details')
        ),
    },
});

export const DEFAULT_ROUTE = ROUTES.LEARNING;
