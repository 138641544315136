/* eslint-disable no-param-reassign */
import { Instance, applySnapshot, types } from 'mobx-state-tree';
import { cloneDeep, isUndefined, map, mapKeys, merge } from 'lodash';

import ApiActionFactory from '../api/api-action-factory';
import { isDev, isJakhub } from '../utils/build-utils';
import { pathSelector } from '../modules/utils/module-utils';

import ApplicationStoreInstance from './application-store';

import { MenuItemModel } from './user-profile-store/models/admin-menu-model';
import { CurrentUserInfo } from './models/current-user';
import { GenericBackendResponse } from './models/generic-backend-response';
import { EmailConfirmation } from './models/email-confirmation';
import { LoginData } from './models/login-data';
import { LoginFormData } from './models/login-form-data';
import InvoiceModel from './user-profile-store/models/invoice-model';
import ProfileDetailsOptionsModel from './user-profile-store/models/profile-details-options-model';
import StoreFactory from './factories/store';
import { UserAccountModel } from './user-profile-store/user-account-model';
import {
    UserAccountMessagesModel,
    UserAccountFullMessageArrayModel,
} from './user-profile-store/user-account-messages-model';
import { MODULES } from '../modules/modules';
import { getValueWithPath, setValueWithPath } from '../utils/utils';
import PasswordChangeModel from './models/password-change-model';
import ChangeEmailConfirmationModel from './models/change-email-confirmation';
import PublicProfileModel from './user-profile-store/models/public-profile-model';
import ProjectModel from './models/project-model';
import SchoolCreationModel from './models/school-creation-model';
import SupportTicketsModel from './models/support-tickets-model';

import {
    ADMIN_MENU_ENDPOINT,
    CHECK_EMAIL_TOKEN_ENDPOINT,
    SAVE_USER_PROFILE_ENDPOINT,
    SAVE_SECTION_VISIBILITY_ENDPOINT,
    SAVE_EMAIL_SUBSCRIPTIONS_ENDPOINT,
    USER_ACCOUNT_ENDPOINT,
    USER_ACCOUNT_LIGHT_ENDPOINT,
    USER_CHANGE_PRIMARY_EMAIL_ENDPOINT,
    USER_CHANGE_PASSWORD_ENDPOINT,
    USER_LOGIN_ENDPOINT,
    USER_LOGOUT_ENDPOINT,
    USER_PROFILE_DETAILS_LIST_ENDPOINT,
    TRIGGER_USER_RESET_PASSWORD_ENDPOINT,
    USER_RESET_NEW_PASSWORD_ENDPOINT,
    USER_SIGNUP_ENDPOINT,
    PUBLIC_PROFILE_ENDPOINT,
    GET_INVOICE_DETAILS_ENDPOINT,
    USER_RESEND_VALIDATION_EMAIL_ENDPOINT,
    VALIDATE_USER_ACCOUNT_ENDPOINT,
    USER_VALIDATE_PRIMARY_EMAIL_CHANGE_ENDPOINT,
    RETRIEVE_USER_ACCOUNT_MESSAGES,
    DELETE_USER_ACCOUNT_MESSAGES,
    USER_ACCOUNT_MESSAGE_INVITATION_RESPONSE,
    SET_USER_ACCOUNT_MESSAGES_READ_FLAG,
    RETRIEVE_USER_ACCOUNT_MESSAGE_BY_ID,
    USER_SIGN_SUBSCRIPTION_TERMS_ENDPOINT,
    TEMPORARY_USER_PROJECT_LIST_STATIC_ENDPOINT,
    SUPPORT_TICKETS_INFO_ENDPOINT,
    SUPPORT_TICKETS_ENDPOINT,
    PROJECT_INVITATION_HAS_ACCOUNT,
} from '../api/endpoints';
import { AccountMessagesDataIsDirty, PublicProfileDataIsDirty } from '../signals/profile';
import ApplicationStore from './application-store';
import InvitationResponseModel from './models/invitation-response-model';

let actionAfterLogin = false;

type AccountContext = 'FULL' | 'BASIC';
const onLoadAccount = (context: AccountContext, self: any) => {
    self.getAccountPending = false;
    if (actionAfterLogin) {
        actionAfterLogin = false;
        if (
            !self.userAccount.profile.isVerified &&
            !self.profileStoreWantsToGoTo.goto &&
            self.profileStoreWantsToGoTo.goto !== ''
        ) {
            self.profileStoreWantsToGoTo = {
                goto: pathSelector(MODULES.SETTINGS.routes.ACCOUNT_SETTINGS),
            };
        } else if (!isJakhub()) {
            window.location.reload();
        }
    }

    if (self?.userAccount?.profile?.isAKEmployee) {
        ProfileStoreInstance.getAdminMenuStructure();
    }

    if (self?.userAccount?.profile?.unreadMessages) {
        self.userAccountUnreadMessages = self?.userAccount?.profile?.unreadMessages;
    }

    self.userIsLoggedIn = true; // Won't get executed if the endpoint returned an error
};

const onTriggerAccount = (context: AccountContext, self: any) => {
    self.getAccountPending = true;
    self.userLoginFormData.password = '';
};

const onErrorAccount = (context: AccountContext, self: any, error?: string) => {
    self.getAccountPending = false;
    console.log('error :  ', error);
    if (!isUndefined(error) && self.loginWasAttempted === true) {
        self.errors = [error];
        if (self.userIsLoggedIn) {
            self.userLogout();
        }
    }
};

const { getUserLocale } = ApplicationStore;
function getLocalizedLabels(mstMap: any) {
    if (!mstMap) return {};

    const locale = getUserLocale().replace('-', '_');

    const returnThis: Record<string, string> = {};

    const mapSet = new Set(mstMap.keys());
    const mapArray = Array.from(mapSet);
    mapArray.map((k) => (returnThis[k as string] = mstMap.get(k)[locale].split('- ').pop()));

    return returnThis;
}

const ProfileStore = StoreFactory({
    modelName: 'ProfileStore',
    models: [
        ApiActionFactory({
            userSignup: {
                endpoint: USER_SIGNUP_ENDPOINT,
                target: 'signupResponse',
                getServerDate: true,
                onTrigger: (self) => {
                    self.loginInProgress = true;
                    self.clearErrors();
                },
                onLoad: (self) => {
                    self.loginInProgress = false;
                    self.getAccountAfterSuccess('BASIC', {
                        goto: self.gotoAfterSignup,
                    });
                },
                onError: (self, error: string[]) => {
                    self.loginInProgress = false;
                    if (!isUndefined(error)) {
                        self.errors = error;
                        if (self.userIsLoggedIn) {
                            self.userLogout();
                        }
                    }
                },
            },
            triggerForgottenUserPasswordReset: {
                endpoint: TRIGGER_USER_RESET_PASSWORD_ENDPOINT,
                target: 'pwResetResponse',
                onTrigger: (self) => self.clearErrors(),
            },
            userSetNewPassword: {
                endpoint: USER_RESET_NEW_PASSWORD_ENDPOINT,
                target: 'unwantedReturnPayload',
                onLoad: (self) => {
                    self.getAccountAfterSuccess('FULL');
                },
                onTrigger: (self) => {
                    self.clearErrors();
                },
            },
            userResendValidationEmail: {
                endpoint: USER_RESEND_VALIDATION_EMAIL_ENDPOINT,
                target: '',
            },
            userPasswordChange: {
                endpoint: USER_CHANGE_PASSWORD_ENDPOINT,
                target: 'pwChangeResponse',
                onTrigger: (self) => {
                    self.pwChangeComplete = false;
                    self.clearErrors();
                },
                onLoad: (self) => {
                    self.pwChangeComplete = true;
                },
            },
            userLogin: {
                endpoint: USER_LOGIN_ENDPOINT,
                target: 'userLoginData',
                onTrigger: (self) => {
                    self.loginWasAttempted = true;
                    self.clearErrors();
                },
                onLoad: (self) => {
                    self.getAccountAfterSuccess('BASIC', {
                        goto: self.userLoginData.redirect,
                    });
                },
                onError: (self, error: string) => {
                    self.userLoginFormData.password = '';
                    if (!isUndefined(error)) {
                        self.errors = [error];
                        if (self.userIsLoggedIn) {
                            self.userLogout();
                        }
                    }
                },
            },
            userLogout: {
                endpoint: USER_LOGOUT_ENDPOINT,
                target: 'userLoginData',
                onLoad: (self) => {
                    ApplicationStoreInstance.hideAllHeaderTools();
                    self.clearUserInfo();
                    self.loginWasAttempted = false;
                    if (!isJakhub()) {
                        window.location.reload();
                    }
                    self.userAccount = {};
                    self.userAccount.profile.isAKEmployee = false;
                    self.userAccount.hasFullProfile = null;
                    self.adminMenuStructure = null;
                },
            },
            changeUserAccountPrimaryEmail: {
                endpoint: USER_CHANGE_PRIMARY_EMAIL_ENDPOINT,
                target: 'userChangeEmailConfirmation',
                onTrigger(self: any) {
                    self.userChangeEmailConfirmation = { code: 0, err: '', random: '' };
                },
            },
            validateUserAccountPrimaryEmailChange: {
                endpoint: USER_VALIDATE_PRIMARY_EMAIL_CHANGE_ENDPOINT,
                target: 'userChangeEmailConfirmation',
                onLoad(self: any) {
                    self.getAccountAfterSuccess('FULL', {
                        goto: pathSelector(MODULES.SETTINGS.routes.ACCOUNT_SETTINGS),
                    });
                },
                onTrigger(self: any) {
                    self.userChangeEmailConfirmation = { code: 0, err: '', random: '' };
                },
            },
            saveUserAccountOnServer: {
                endpoint: SAVE_USER_PROFILE_ENDPOINT,
                target: 'userAccount',
                onLoad: (self) => {
                    self.callCounter = self.callCounter + 1;
                },
            },
            saveEmailSubscriptionsOnServer: {
                endpoint: SAVE_EMAIL_SUBSCRIPTIONS_ENDPOINT,
                target: 'target_set_in_calling_function',
            },
            saveSectionVisibilityOnServer: {
                endpoint: SAVE_SECTION_VISIBILITY_ENDPOINT,
                target: 'target_set_in_calling_function',
            },
            verifyEmailToken: {
                endpoint: CHECK_EMAIL_TOKEN_ENDPOINT,
                target: 'userEmailConfirmation',
            },
            validateUserAccountOnServer: {
                endpoint: VALIDATE_USER_ACCOUNT_ENDPOINT,
                target: 'unwantedReturnPayload',
                onLoad: (self) => self.getAccountAfterSuccess('FULL'),
                onError: (self, error: string) => {
                    if (!isUndefined(error)) {
                        self.errors = [error];
                    }
                },
            },
            getUserAccountFull: {
                endpoint: USER_ACCOUNT_ENDPOINT,
                target: 'userAccount',
                onLoad: (self) => {
                    onLoadAccount('FULL', self);
                    self.userAccount.hasFullProfile = true;
                },
                onTrigger: (self) => {
                    onTriggerAccount('FULL', self);
                },
                onError: (self, error: string) => {
                    onErrorAccount('FULL', self, error);
                },
            },
            getUserAccountBasic: {
                endpoint: USER_ACCOUNT_LIGHT_ENDPOINT,
                target: 'userAccount',
                onLoad: (self) => {
                    onLoadAccount('BASIC', self);
                    self.userAccount.hasFullProfile = false;
                },
                onTrigger: (self) => {
                    onTriggerAccount('BASIC', self);
                },
                onError: (self, error: string) => {
                    onErrorAccount('BASIC', self, error);
                },
            },
            getUserProfileDetailsLists: {
                endpoint: USER_PROFILE_DETAILS_LIST_ENDPOINT,
                target: 'profileDetailsLists',
            },
            getPublicProfile: {
                endpoint: PUBLIC_PROFILE_ENDPOINT,
                target: 'userPublicProfile',
                onTrigger: () => {
                    PublicProfileDataIsDirty.value = false;
                },
            },
            retrieveUserProjects: {
                endpoint: TEMPORARY_USER_PROJECT_LIST_STATIC_ENDPOINT,
                target: 'userProjects',
                payloadDataLocationId: 'projects',
            },
            getInvoice: {
                endpoint: GET_INVOICE_DETAILS_ENDPOINT,
                target: 'invoices',
                targetMapId: 'transactionId',
                getServerDate: true,
            },
            getUserAccountMessages: {
                endpoint: RETRIEVE_USER_ACCOUNT_MESSAGES,
                target: 'userAccountMessages',
                onLoad: () => {
                    AccountMessagesDataIsDirty.value = false;
                },
            },
            deleteMessages: {
                endpoint: DELETE_USER_ACCOUNT_MESSAGES,
                target: 'unwantedReturnPayload',
                onLoad: (self) => {
                    AccountMessagesDataIsDirty.value = true;

                    if (self?.unwantedReturnPayload?.totalUnreadMessages) {
                        self.userAccountUnreadMessages =
                            self?.unwantedReturnPayload?.totalUnreadMessages;
                    }
                },
            },
            setMessageReadFlag: {
                endpoint: SET_USER_ACCOUNT_MESSAGES_READ_FLAG,
                target: 'unwantedReturnPayload',
                onLoad: (self) => {
                    AccountMessagesDataIsDirty.value = true;

                    if (self?.unwantedReturnPayload?.totalUnreadMessages !== undefined) {
                        self.userAccountUnreadMessages =
                            self?.unwantedReturnPayload?.totalUnreadMessages;
                    }
                },
            },
            testIfInvitationHasAccount: {
                endpoint: PROJECT_INVITATION_HAS_ACCOUNT,
                target: 'invitationHasAccount',
                payloadDataLocationId: 'hasAccount',
                onTrigger(self: any) {
                    self.invitationHasAccount = null;
                },
            },
            respondToInvitation: {
                endpoint: USER_ACCOUNT_MESSAGE_INVITATION_RESPONSE,
                target: 'invitationResponse',
                onLoad: (self) => {
                    AccountMessagesDataIsDirty.value = true;
                    self.getUserAccountBasic();
                },
            },
            getMessageContent: {
                endpoint: RETRIEVE_USER_ACCOUNT_MESSAGE_BY_ID,
                target: 'userAccountLoadedMessages',
            },
            getAdminMenuStructure: {
                endpoint: ADMIN_MENU_ENDPOINT,
                target: 'adminMenuStructure',
            },
            signDocuments: {
                endpoint: USER_SIGN_SUBSCRIPTION_TERMS_ENDPOINT,
                target: 'signedDocuments',
            },
            getSupportTicketsInfoFromBE: {
                endpoint: SUPPORT_TICKETS_INFO_ENDPOINT,
                target: 'supportTickets.info',
            },
            getSupportTickets: {
                endpoint: SUPPORT_TICKETS_ENDPOINT,
                target: 'supportTickets.ticketList',
                onTrigger: (self) => {
                    if (self?.supportTickets?.ticketList?.tickets) {
                        self.supportTickets.ticketList.tickets = [];
                        self.supportTickets.ticketList.ticketCount = 0;
                    }
                },
            },
        }),
    ],
    modelStructure: {
        profileStoreWantsToGoTo: types.frozen({}),
        unwantedReturnPayload: types.frozen({}),
        signedDocuments: types.frozen({}),
        invitationHasAccount: types.maybeNull(types.boolean),
        invitationResponse: types.maybeNull(InvitationResponseModel),
        gotoAfterSignup: '',
        callCounter: 0,
        loadingCounter: 0,
        isLoading: false,
        getAccountPending: false,
        serverDate: '',
        signupResponse: types.maybeNull(GenericBackendResponse),
        pwResetResponse: types.maybeNull(CurrentUserInfo),
        pwChangeResponse: types.maybeNull(PasswordChangeModel),
        pwChangeComplete: false,
        userChangeEmailConfirmation: types.maybeNull(ChangeEmailConfirmationModel),
        userEmailConfirmation: types.maybeNull(EmailConfirmation),
        userLoginData: types.maybeNull(LoginData),
        userLoginFormData: LoginFormData,
        userIsLoggedIn: false,
        loginInProgress: false,
        loginWasAttempted: false,
        userAccountUnreadMessages: 0,
        userAccount: types.optional(UserAccountModel, {}),
        userAccountMessages: types.optional(UserAccountMessagesModel, {}),
        userAccountLoadedMessages: types.optional(UserAccountFullMessageArrayModel, {}),
        userProjects: types.array(ProjectModel),
        userPublicProfile: types.maybeNull(PublicProfileModel),
        invoices: types.map(InvoiceModel),
        userInfo: types.maybeNull(CurrentUserInfo),
        errors: types.array(types.string),
        successes: types.array(types.string),
        profileDetailsLists: types.maybeNull(ProfileDetailsOptionsModel),
        adminMenuStructure: types.maybeNull(MenuItemModel),
        callTracker: types.frozen({}),
        schoolCreation: types.maybeNull(SchoolCreationModel),
        supportTickets: SupportTicketsModel,
    },
})
    .actions((self) => ({
        afterCreate() {
            self.getUserAccountBasic();
        },
        retrieveUserProfileDetailsLists() {
            if (!self.profileDetailsLists) {
                self.getUserProfileDetailsLists();
            }
        },
    }))
    .actions((self) => ({
        getUserAccount(context: AccountContext) {
            if (self.getAccountPending || self.userAccount.hasFullProfile === true) return;
            if (context === 'BASIC') {
                self.getUserAccountBasic();
                return;
            }
            self.getUserAccountFull();
        },
        getUserProjects() {
            if (!self.userProjects.length) {
                self.retrieveUserProjects();
            }
        },
    }))
    .actions((self) => ({
        getAccountAfterSuccess(context: AccountContext, whatsNext: any = {}) {
            const { goto } = whatsNext;
            if (goto) {
                self.profileStoreWantsToGoTo = { goto };
            }

            actionAfterLogin = true;

            self.getUserAccount(context);

            self.userLoginFormData.password = '';
            self.userLoginFormData.confirmPassword = '';
        },
        postSignUpTarget(newTarget: string) {
            self.gotoAfterSignup = newTarget;
        },
        saveDeletedDataUserAccount(newData: any, path: string) {
            // Save data locally until api call returns
            // This removes the apparent lag between clicking
            // a switch, sending info to BE, waiting for confirmation
            // and displaying new version of userAccount

            const cloneUserAccount = cloneDeep(self.userAccount);
            setValueWithPath(cloneUserAccount, path, getValueWithPath(newData, path));
            self.userAccount = cloneUserAccount;
            self.saveUserAccountOnServer(newData);
        },
        validateUserAccount(newData: any) {
            const {
                emailToken,
                account: { confirmPassword: password },
                profile: {
                    firstName,
                    lastName,
                    details: {
                        company,
                        companySize,
                        title: jobTitle,
                        titleOther: jobTitleOther,
                        countryCode,
                    },
                },
            } = newData;

            self.validateUserAccountOnServer({
                emailToken,
                password,
                userInfo: {
                    firstName,
                    lastName,
                    company,
                    companySize,
                    jobTitle,
                    jobTitleOther,
                    countryCode,
                },
            });
        },
        setVisibility(dataMap: string, newData: any) {
            self.callTracker = { ...self.callTracker, [dataMap]: null };
            self.saveSectionVisibilityOnServer({
                formData: newData,
                targetPath: dataMap,
                dataOnError: !newData.value,
            });

            PublicProfileDataIsDirty.value = true;
        },
        setEmailSubscriptions(dataMap: string, newData: any) {
            self.callTracker = { ...self.callTracker, [dataMap]: null };
            self.saveEmailSubscriptionsOnServer({
                formData: newData,
                targetPath: dataMap,
                dataOnError: !newData.value,
            });
        },
        saveUserAccount(newData: any) {
            // Save data locally until api call returns
            // This removes the apparent lag between clicking
            // a switch, sending info to BE, waiting for confirmation
            // and displaying new version of userAccount

            PublicProfileDataIsDirty.value = true;

            const cloneUserAccount = cloneDeep(self.userAccount);
            const cloneNewData = cloneDeep(newData);
            self.userAccount = merge(cloneUserAccount, cloneNewData);

            self.saveUserAccountOnServer(newData);
        },
        changePassword(currentPassword: string, newPassword: string) {
            self.userPasswordChange({
                current_password: currentPassword,
                new_password: newPassword,
            });
        },
        updateUserLoginFormData(loginFormData: {
            email: string;
            password: string;
            loginAttempted: boolean;
        }) {
            self.userLoginFormData = loginFormData;
        },
        clearUserInfo() {
            self.userIsLoggedIn = false;
            applySnapshot(self.userAccount, {});
        },
        clearState() {
            self.pwResetResponse = {};
            self.signupResponse = {};
            self.pwChangeResponse = {};
        },
        clearErrors() {
            self.errors = [];
        },
        retrieveInvoiceById(transactionId: string) {
            if (!self.invoices.get(transactionId)) {
                self.getInvoice({ transactionId });
            }
        },
        retrieveMessageContentById(messageIds: string[]) {
            if (messageIds.length > 0) {
                self.getMessageContent({ UUIDs: messageIds });
            }
        },
        changeUserAccountPrimaryEmailInStoreThenSaveToServer(newData: any) {
            self.userAccount.account.emails.primaryChangeTo = newData.email;
            self.changeUserAccountPrimaryEmail(newData);
        },
    }))
    .actions((self) => ({
        clearGoto() {
            self.profileStoreWantsToGoTo = {};
        },
    }))
    .views((self) => ({
        passwordChangeMessages(): string[] {
            return self.pwChangeResponse?.messages;
        },
        retrieveSupportTickets() {
            return {
                tickets: self.supportTickets?.ticketList?.tickets || [],
                ticketCount: self.supportTickets?.ticketList?.ticketsCount,
            };
        },
        getSupportTicketsInfo() {
            if (!self.supportTickets?.info?.fields?.length) {
                self.getSupportTicketsInfoFromBE();
            }
        },
        retrieveSupportTicketsInfo() {
            return {
                fields: mapKeys(self.supportTickets?.info?.fields, (i) => i.fieldName) || {},
                severityLabels: getLocalizedLabels(self.supportTickets?.info?.severityLabels),
                statusLabels: getLocalizedLabels(self.supportTickets?.info?.statusLabels),
            };
        },
        getJobTypes() {
            return map(self.profileDetailsLists?.jobTypes, (j: string, jId: string) => ({
                key: jId,
                value: j,
            }));
        },
        getInvoiceById(transactionId: string) {
            return self.invoices.get(transactionId)?.items;
        },
        getMessageContentById(messageId: string) {
            return [...self.userAccountLoadedMessages.messages].find((m) => messageId === m.UUID);
        },
    }));
export type TypeProfileStore = Instance<typeof ProfileStore>;

const ProfileStoreInstance = ProfileStore.create({
    pwResetResponse: {},
    pwChangeResponse: {},
    userAccount: {},
    userLoginFormData: {},
    schoolCreation: {},
    supportTickets: {},
    errors: [],
    invitationHasAccount: null,
});

declare const window: Window &
    typeof globalThis & {
        ProfileStore: typeof ProfileStoreInstance;
    };

if (window && isDev()) {
    window.ProfileStore = ProfileStoreInstance;
}

export default ProfileStoreInstance;
