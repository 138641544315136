import { Instance, types } from 'mobx-state-tree';

const FutureReleaseCollectionModel = types.model('FutureReleaseCollectionModel', {
    imgSrc: '',
    title: '',
    soundCount: 0,
});

const FutureReleaseModel = types.model('FutureReleaseModel', {
    date: types.array(types.number),
    collections: types.array(FutureReleaseCollectionModel),
});

const StatisticsModel = types.model('StatisticsModel', {
    nb_sound_fx: 0,
    nb_source_items: 0,
});

const FuturePriceModel = types.model('StatisticsModel', {
    futurePrice: types.string,
    triggerDate: types.string,
    increaseDate: types.string,
});

export const StrataAdditionalInfoModel = types.model('StrataAdditionalInfoModel', {
    statistics: StatisticsModel,
    new_release_ids: types.array(types.string),
    boom_exclusive_ids: types.array(types.string),
    future_releases: types.array(FutureReleaseModel),
    price_increase: FuturePriceModel,
});

export type TypeFutureReleaseCollectionModel = Instance<typeof FutureReleaseCollectionModel>;
export type TypeFutureReleaseModel = Instance<typeof FutureReleaseModel>;
export type TypeStatisticsModel = Instance<typeof StatisticsModel>;
export type TypeStrataAdditionalInfoModel = Instance<typeof StrataAdditionalInfoModel>;

export default StrataAdditionalInfoModel;
