import React from 'react';
import { map, includes } from 'lodash';

import { useLocation } from 'react-router-dom';
import { ModuleId, RouteId, TargetType } from '../../../modules/utils/module-interfaces';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import {
    getPredicatedModules,
    getSelectedModules,
    isValidModule,
} from '../../../modules/utils/module-utils';
import { DEFAULT_ROUTE, MODULES } from '../../../modules/modules';
import { injectStores } from '../../../stores/factories/store-utils';
import { HeaderProps } from './utils/header-interfaces';
import { isJakhub, isJakhubForChina, upperSnakeCase } from '../../../utils/utils';

import { supportedLanguages } from '../../../i18n/supported-locales';

import { SettingsTopMenu } from '../../modules/account-settings/account-settings';
import ContactForms from '../../common/contact-forms/contact-forms';
import DivButton from '../../common/legos/div-button/div-button';
import EmployeeAdminMenu from '../menu/employee-admin-menu/employee-admin-menu';
import HeaderTools from './utils/header-tools';
import SpecialMenuMessage from '../../common/menu-message/special-menu-message';
import UnverifiedUserMenuMessage from '../../common/menu-message/unverified-user-menu-message';
import MainMenu from '../menu/main-menu/main-menu';
import MobileMenu from '../menu/mobile-menu/mobile-menu';
import PortalDrawer from '../../common/portal-drawer/portal-drawer';
import SubMenu from '../menu/main-menu/sub-menu';

import './header.scss';

const currentModuleName = (menuState: Record<string, ModuleId>) => {
    const { currentModuleId, currentSubModuleId } = menuState;
    const moduleId = currentModuleId as ModuleId;
    const subModuleId = (currentSubModuleId ? currentSubModuleId : currentModuleId) as RouteId;

    const { i18nId } = MODULES[moduleId].routes[subModuleId];
    return { i18nId, subModuleId };
};

const locationToModuleId = (
    pathname: string
): { currentModuleId: ModuleId; currentSubModuleId: ModuleId } => {
    const appPathWithoutLang = ((pathString) => {
        // Remove the first '/' then split into an array of segments
        const pathArray = pathString.substring(1).split('/');
        // First segment is a Language slug?
        const segments = supportedLanguages
            .map((lang) => lang.toUpperCase())
            .includes(pathArray[0].toUpperCase())
            ? pathArray.slice(1)
            : pathArray;
        return `/${segments.join('/')}`;
    })(pathname);

    const [currentModuleId, currentSubModuleId] = map(
        appPathWithoutLang.substring(1).split('/'),
        (i) => upperSnakeCase(i) as ModuleId | MagicSlug | 'GAME_JAMS'
    );

    const { moduleIsValid, subModuleIsValid } = isValidModule({
        module: currentModuleId,
        subModule: currentSubModuleId,
    } as TargetType<ModuleId>);

    if (moduleIsValid && subModuleIsValid) {
        return {
            currentModuleId: currentModuleId as ModuleId,
            currentSubModuleId: currentSubModuleId as RouteId,
        };
    }

    // SECOND TRY TO IDENTIFY MODULES FOR FCRM LINKS
    const fcrmMagicList = {
        BLOG: 'COMMUNITY',
        COMMUNITY: 'COMMUNITY',
        DIRECTORY: 'COMMUNITY',
        QA: 'COMMUNITY',
        DISCOVER: 'SOLUTIONS',
        LEARN: 'LEARNING',
        COURSES: 'LEARNING',
        PRODUCTS: 'PRODUCTS',
        LIBRARY: 'DOCUMENTATION',
    } as const;
    type MagicSlug = keyof typeof fcrmMagicList;

    let magicRootSegment = fcrmMagicList[currentModuleId as MagicSlug];
    let magicSecondarySegment = currentModuleId === 'COURSES' ? 'LEARN_WWISE' : currentSubModuleId;
    magicSecondarySegment =
        currentModuleId === 'DIRECTORY' ? 'CREATORS_DIRECTORY' : magicSecondarySegment;
    magicSecondarySegment = currentModuleId === 'QA' ? 'QUESTIONS_ANSWERS' : magicSecondarySegment;
    magicSecondarySegment = currentModuleId === 'LIBRARY' ? 'DOCUMENTATION' : magicSecondarySegment;
    magicSecondarySegment =
        currentModuleId === 'LEARNING' && currentSubModuleId === 'GAME_JAMS'
            ? 'EVENTS'
            : magicSecondarySegment;

    if (window.location.hostname.indexOf('blog') !== -1) {
        magicRootSegment = 'COMMUNITY';
        magicSecondarySegment = 'BLOG';
    }

    const { moduleIsValid: fallBackModuleIsValid, subModuleIsValid: fallBackSubModuleIsValid } =
        isValidModule({
            module: magicRootSegment as ModuleId,
            subModule: magicSecondarySegment,
        } as TargetType<ModuleId>);
    return {
        currentModuleId: fallBackModuleIsValid ? magicRootSegment : DEFAULT_ROUTE.id,
        currentSubModuleId:
            fallBackModuleIsValid && fallBackSubModuleIsValid
                ? (magicSecondarySegment as ModuleId)
                : ('' as ModuleId),
    };
};

const countSubModules = (moduleId: ModuleId) => {
    const currentModuleSubRoutes = getSelectedModules(MODULES, [moduleId])[moduleId]?.routes || {};
    const entriesToDisplay =
        getPredicatedModules(currentModuleSubRoutes, (module) => module.displayInMenu !== false) ||
        {};
    return Object.keys(entriesToDisplay).length;
};

export function JakhubHelmet({ pageTitle }: { pageTitle?: string }) {
    const chinaSuffix = isJakhubForChina() ? '.cn' : '';
    return (
        <Helmet>
            <title>
                {pageTitle ? `${pageTitle} | ` : ''}Audiokinetic{chinaSuffix}
            </title>
        </Helmet>
    );
}

function Header(props: HeaderProps) {
    const { t } = useTranslation();
    const {
        ApplicationStore: {
            headerTools: { mobileMenuOpened },
            toggleMobileMenu,
        },
    } = props;

    const { pathname } = useLocation();
    const menuState = locationToModuleId(pathname);

    // Show sub-menu only whenever there are multiple entries to show
    const showSubMenu = countSubModules(menuState.currentModuleId) > 0;
    const { i18nId, subModuleId } = currentModuleName(menuState);
    const moduleName = subModuleId !== 'HOME' ? t(i18nId) : '';
    const doNotChangeTitle =
        includes(['DOCUMENTATION', 'BLOG'], subModuleId) || menuState.currentModuleId === 'SEARCH';

    return (
        <header id='jakhub-menu-header' className='jakhub-menu-header'>
            {isJakhub() || !doNotChangeTitle ? <JakhubHelmet pageTitle={moduleName} /> : null}
            <EmployeeAdminMenu />
            <div className='header-main'>
                <div className='header-main-content'>
                    <DivButton className='hamburger' onClick={toggleMobileMenu}>
                        <i className='material-icons'>{!mobileMenuOpened ? 'menu' : 'close'}</i>
                    </DivButton>
                    <MainMenu menuState={menuState} />
                    <HeaderTools />
                </div>
            </div>
            {showSubMenu ? <SubMenu menuState={menuState} /> : null}
            {menuState.currentModuleId === 'SETTINGS' ? <SettingsTopMenu /> : null}
            <SpecialMenuMessage />
            <UnverifiedUserMenuMessage />
            <MobileMenu menuState={menuState} />
            <PortalDrawer />
            <ContactForms />
        </header>
    );
}

export default injectStores(['ApplicationStore'])(Header);
