import { typedRoutes } from './utils/module-interfaces';

import { lazy } from 'react';
import { PATHS } from './soft-paths/strata';

export const ROUTES = typedRoutes({
    STRATA: {
        ...PATHS.STRATA,
        id: 'OVERVIEW',
        i18nId: 'strata:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/products/strata/strata')),
    },
    OVERVIEW: {
        ...PATHS.OVERVIEW,
        id: 'OVERVIEW',
        i18nId: 'strata:overview.menu-label',
        component: lazy(() => import('../components/modules/products/strata/strata')),
    },
    COLLECTIONS_ID: {
        ...PATHS.COLLECTIONS_ID,
        id: 'COLLECTIONS_ID',
        i18nId: 'strata:collections.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store/strata/strata')),
    },
    COLLECTIONS: {
        ...PATHS.COLLECTIONS,
        id: 'COLLECTIONS',
        i18nId: 'strata:collections.menu-label',
        component: lazy(() => import('../components/modules/store/strata/strata')),
    },
    COLLECTION: {
        ...PATHS.COLLECTION,
        id: 'COLLECTION',
        name: 'Collection detail page',
        i18nId: 'store:module.strata.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store/collection/collection')),
    },
    PRICING: {
        ...PATHS.PRICING,
        id: 'PRICING',
        i18nId: 'wwise:pricing.menu-label',
        component: lazy(() => import('../components/modules/store/strata-pricing/strata-pricing')),
    },
    LICENSING: {
        ...PATHS.LICENSING,
        id: 'LICENSING',
        i18nId: 'wwise:licensing-faq.menu-label',
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    REGISTER_PROJECT: {
        ...PATHS.REGISTER_PROJECT,
        id: 'REGISTER_PROJECT',
        i18nId: 'wwise:register-project.menu-label',
    },
});

export const DEFAULT_ROUTE = ROUTES.STRATA;
