/* Home Module */
import { DEFAULT_ROUTE as defaultRouteHome, ROUTES as routesHome } from './home';

/* Solutions Module */
import { DEFAULT_ROUTE as defaultRouteCustomers, ROUTES as routesCustomers } from './customers';

/* Solutions Module */
import { DEFAULT_ROUTE as defaultRouteSolutions, ROUTES as routesSolutions } from './solutions';

/* Products Module */
import { DEFAULT_ROUTE as defaultRouteProducts, ROUTES as routesProducts } from './products';

/* Learning (NEW Education) Module */
import { DEFAULT_ROUTE as defaultRouteLearning, ROUTES as routesLearning } from './learning';

/* Community Module */
import { DEFAULT_ROUTE as defaultRouteCommunity, ROUTES as routesCommunity } from './community';

/* Store Module */
import { DEFAULT_ROUTE as defaultRouteStore, ROUTES as routesStore } from './store';

/* Help Module */
import { DEFAULT_ROUTE as defaultRouteHelp, ROUTES as routesHelp } from './help';

/* Modules that have no associated components */
import { DEFAULT_ROUTE as defaultRouteGeneral, ROUTES as routesGeneral } from './general';

/* Modules that have no associated components */
import { DEFAULT_ROUTE as defaultRouteLegal, ROUTES as routesLegal } from './legal';

/* Modules that have no associated components */
import {
    DEFAULT_ROUTE as defaultRouteLicensesSubscriptions,
    ROUTES as routesLicensesSubscriptions,
} from './licenses-subscriptions';

/* Purchasing Terms and Conditions Module */
import { DEFAULT_ROUTE as defaultRouteTerms, ROUTES as routesTerms } from './terms';

/* Search Module */
import { DEFAULT_ROUTE as defaultRouteSearch, ROUTES as routesSearch } from './search';

/* Settings Module */
import { DEFAULT_ROUTE as defaultRouteSettings, ROUTES as routesSettings } from './settings';

/* Profile Module */
import { DEFAULT_ROUTE as defaultRouteProfile, ROUTES as routesProfile } from './profile';

/* Messages Module */
import { DEFAULT_ROUTE as defaultRouteMessages, ROUTES as routesMessages } from './messages';

/* Wwise Module */
import { DEFAULT_ROUTE as defaultRouteWwise, ROUTES as routesWwise } from './wwise';

/* Strata Module */
import { DEFAULT_ROUTE as defaultRouteStrata, ROUTES as routesStrata } from './strata';

/* Documentation Module */
import {
    DEFAULT_ROUTE as defaultRouteDocumentation,
    ROUTES as routesDocumentation,
} from './documentation';

/* FCRM routes that are no longer valid and have a Jakhub equivalent */
import { DEFAULT_ROUTE as defaultRouteFcrm, ROUTES as routesFcrm } from './fcrm-redirects';

/* Modules that have no associated components */
import { DEFAULT_ROUTE as defaultRouteFooter, ROUTES as routesFooter } from './footer';

import { DEFAULT_ROUTE as defaultRouteThankYou, ROUTES as routesThankYou } from './thank-you';

import { featureFlags } from '../utils/feature-flags';

export const MODULES = {
    HOME: {
        id: 'HOME',
        name: 'Home module',
        i18nId: 'home:module.menu-label',
        routes: routesHome,
        defaultRoute: defaultRouteHome,
        displayInMenu: false,
    },
    WWISE: {
        id: 'WWISE',
        name: 'Wwise module',
        i18nId: 'wwise:module.menu-label',
        routes: routesWwise,
        defaultRoute: defaultRouteWwise,
    },
    STRATA: {
        id: 'STRATA',
        name: 'Strata module',
        i18nId: 'strata:module.menu-label',
        routes: routesStrata,
        defaultRoute: defaultRouteStrata,
    },
    PRODUCTS: {
        id: 'PRODUCTS',
        name: 'Products module',
        i18nId: 'products:module.menu-label',
        routes: routesProducts,
        defaultRoute: defaultRouteProducts,
        displayInMenu: false,
    },
    SOLUTIONS: {
        id: 'SOLUTIONS',
        name: 'Solutions module',
        i18nId: 'solutions:module.menu-label',
        routes: routesSolutions,
        defaultRoute: defaultRouteSolutions,
    },
    LEARNING: {
        id: 'LEARNING',
        name: 'Learning module',
        i18nId: 'learning:module.menu-label',
        routes: routesLearning,
        defaultRoute: defaultRouteLearning,
    },
    COMMUNITY: {
        id: 'COMMUNITY',
        name: 'Community module',
        i18nId: 'community:module.menu-label',
        routes: routesCommunity,
        defaultRoute: defaultRouteCommunity,
    },
    STORE: {
        id: 'STORE',
        name: 'Store module',
        i18nId: 'store:module.menu-label',
        routes: routesStore,
        defaultRoute: defaultRouteStore,
        displayInMenu: false,
    },
    DOCUMENTATION: {
        id: 'DOCUMENTATION',
        name: 'Documentation module',
        i18nId: 'documentation:module.menu-label',
        routes: routesDocumentation,
        defaultRoute: defaultRouteDocumentation,
    },
    HELP: {
        id: 'HELP',
        name: 'Help module',
        i18nId: 'help:module.menu-label',
        routes: routesHelp,
        defaultRoute: defaultRouteHelp,
    },
    GENERAL: {
        id: 'GENERAL',
        name: 'General module',
        i18nId: 'general:module.menu-label',
        routes: routesGeneral,
        defaultRoute: defaultRouteGeneral,
        displayInMenu: featureFlags.FEATURE_FLAGS_ADMIN,
    },
    FOOTER: {
        id: 'FOOTER',
        name: 'Footer module',
        i18nId: 'footer:module.menu-label',
        routes: routesFooter,
        defaultRoute: defaultRouteFooter,
        displayInMenu: false,
    },
    LEGAL: {
        id: 'LEGAL',
        name: 'Legal module',
        i18nId: 'footer:module.legal.menu-label',
        routes: routesLegal,
        defaultRoute: defaultRouteLegal,
        displayInMenu: false,
    },
    TERMS: {
        id: 'TERMS',
        name: 'Terms and Conditions module',
        i18nId: 'terms:module.menu-label',
        routes: routesTerms,
        defaultRoute: defaultRouteTerms,
        displayInMenu: false,
    },
    THANKYOU: {
        id: 'THANKYOU',
        name: 'Thank You module',
        i18nId: 'NOT_NECESSARY',
        routes: routesThankYou,
        defaultRoute: defaultRouteThankYou,
        displayInMenu: false,
    },
    SEARCH: {
        id: 'SEARCH',
        name: 'Search',
        i18nId: 'NOT_NECESSARY',
        routes: routesSearch,
        defaultRoute: defaultRouteSearch,
        displayInMenu: false,
    },
    SETTINGS: {
        id: 'SETTINGS',
        name: 'SETTINGS',
        i18nId: 'settings:module.menu-label',
        routes: routesSettings,
        defaultRoute: defaultRouteSettings,
        displayInMenu: false,
    },
    PROFILE: {
        id: 'PROFILE',
        name: 'PROFILE',
        i18nId: 'profile:module.menu-label',
        routes: routesProfile,
        defaultRoute: defaultRouteProfile,
        displayInMenu: false,
    },
    MESSAGES: {
        id: 'MESSAGES',
        name: 'MESSAGES',
        i18nId: 'messges:module.menu-label',
        routes: routesMessages,
        defaultRoute: defaultRouteMessages,
        displayInMenu: false,
    },
    LICENSES_SUBSCRIPTIONS: {
        id: 'LICENSES_SUBSCRIPTIONS',
        name: 'LICENSES_SUBSCRIPTIONS',
        i18nId: 'licenses_subscriptions:module.menu-label',
        routes: routesLicensesSubscriptions,
        defaultRoute: defaultRouteLicensesSubscriptions,
        displayInMenu: false,
    },
    CUSTOMERS: {
        id: 'CUSTOMERS',
        name: 'CUSTOMERS',
        i18nId: 'customers:module.menu-label',
        routes: routesCustomers,
        defaultRoute: defaultRouteCustomers,
        displayInMenu: false,
    },
    OLD_FCRM_ROUTES: {
        id: 'OLD_FCRM_ROUTES',
        name: 'OLD_FCRM_ROUTES',
        i18nId: 'general:module.menu-label',
        routes: routesFcrm,
        defaultRoute: defaultRouteFcrm,
        displayInMenu: false,
    },
} as const;

export const DEFAULT_ROUTE = MODULES.HOME;
