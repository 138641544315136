import { typedRoutes } from './utils/module-interfaces';

import { lazy } from 'react';
import { PATHS } from './soft-paths/wwise';

export const ROUTES = typedRoutes({
    WWISE: {
        ...PATHS.WWISE,
        id: 'WWISE',
        i18nId: 'wwise:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/wwise/wwise')),
    },
    OVERVIEW: {
        ...PATHS.OVERVIEW,
        id: 'OVERVIEW',
        i18nId: 'wwise:overview.menu-label',
        component: lazy(() => import('../components/modules/wwise/wwise')),
    },
    WWISE_SPATIAL_AUDIO: {
        ...PATHS.WWISE_SPATIAL_AUDIO,
        id: 'WWISE_SPATIAL_AUDIO',
        name: 'Wwise Spatial Audio',
        i18nId: 'products:module.wwise-spatial-audio.menu-label',
        displayInMenu: false,
        component: lazy(
            () => import('../components/modules/wwise/wwise-spatial-audio/wwise-spatial-audio')
        ),
    },
    PLUGIN_DETAILS: {
        ...PATHS.PLUGIN_DETAILS,
        id: 'PLUGIN_DETAILS',
        i18nId: 'wwise:plug-ins.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/wwise/plugins/plugin-details')),
    },
    PLUGINS: {
        ...PATHS.PLUGINS,
        id: 'PLUGINS',
        i18nId: 'wwise:plug-ins.menu-label',
        component: lazy(() => import('../components/modules/wwise/plugins/plugins')),
    },
    INTEGRATIONS: {
        ...PATHS.INTEGRATIONS,
        id: 'INTEGRATIONS',
        i18nId: 'wwise:integrations.menu-label',
        component: lazy(() => import('../components/modules/products/integrations/integrations')),
    },
    SUPPORTED_PLATFORMS: {
        ...PATHS.SUPPORTED_PLATFORMS,
        id: 'SUPPORTED_PLATFORMS',
        i18nId: 'wwise:supported-platforms.menu-label',
        component: lazy(
            () => import('../components/modules/wwise/supported-platforms/supported-platforms')
        ),
    },
    POWERED_BY_WWISE: {
        ...PATHS.POWERED_BY_WWISE,
        id: 'POWERED_BY_WWISE',
        i18nId: 'wwise:powered-by-wwise.menu-label',
        component: lazy(
            () =>
                import(
                    '../components/modules/solutions/powered-by-wwise-page/powered-by-wwise-page-v2'
                )
        ),
    },
    PRICING: {
        ...PATHS.PRICING,
        id: 'PRICING',
        i18nId: 'wwise:pricing.menu-label',
        component: lazy(() => import('../components/modules/products/strata/strata')),
    },
    LICENSING: {
        ...PATHS.LICENSING,
        id: 'LICENSING',
        i18nId: 'wwise:licensing-faq.menu-label',
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    REGISTER_PROJECT: {
        ...PATHS.REGISTER_PROJECT,
        id: 'REGISTER_PROJECT',
        i18nId: 'wwise:register-project.menu-label',
    },
});

export const DEFAULT_ROUTE = ROUTES.OVERVIEW;
