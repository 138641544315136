/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';
import ApiActionFactory from '../../api/api-action-factory';
import {
    LIBRARY_CARDS_ENDPOINT,
    ALL_LIBRARY_INFO_ENDPOINT,
    STRATA_ADDITIONAL_INFO_STATIC_ENDPOINT,
} from '../../api/endpoints';
import { filter, includes, isEqual, keys, map, mapKeys, reduce, sortBy } from 'lodash';
import { isDev } from '../../utils/build-utils';

import {
    LibraryCollectionModel,
    TypeLibraryCollectionModel,
} from './models/library-collection-model';

import { StrataAdditionalInfoModel } from './models/strata-additional-info-model';

import StoreFactory from '../factories/store';
import { StrataPrice3Y, StrataPriceAcademic, StrataPriceTrial } from '../../signals/strata-pricing';
import { getFallbackBackendServer, getValueWithPath } from '../../utils/utils';

const AkLibraryStore = StoreFactory({
    models: [
        ApiActionFactory({
            fetchCollectionCards: {
                endpoint: LIBRARY_CARDS_ENDPOINT,
                target: 'collections',
                targetMapId: 'urltag',
            },
            fetchAllLibraryInfo: {
                endpoint: ALL_LIBRARY_INFO_ENDPOINT,
                target: 'libraryPricing',
                onLoad: (self) => {
                    let extracted = getValueWithPath(self.libraryPricing, 'user-based.0');
                    StrataPrice3Y.value = {
                        price: extracted.priceLabel,
                        target: `${getFallbackBackendServer()}${extracted.purchaseLink}`,
                    };

                    extracted = getValueWithPath(self.libraryPricing, 'trial.0');
                    StrataPriceTrial.value = {
                        price: extracted.priceLabel,
                        target: `${getFallbackBackendServer()}${extracted.purchaseLink}`,
                    };

                    extracted = getValueWithPath(self.libraryPricing, 'academic.0');
                    StrataPriceAcademic.value = {
                        price: extracted.priceLabel,
                        target: `${getFallbackBackendServer()}${extracted.purchaseLink}`,
                    };
                },
            },
            getAdditionalInfo: {
                endpoint: STRATA_ADDITIONAL_INFO_STATIC_ENDPOINT,
                target: 'additionalInfo',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        libraryPricing: types.frozen({}),
        collections: types.map(types.array(LibraryCollectionModel)),
        additionalInfo: types.maybeNull(StrataAdditionalInfoModel),
    },
})
    .actions((self) => ({
        retrieveAdditionalInfo() {
            if (!self.additionalInfo) {
                self.getAdditionalInfo();
            }
        },
        retrieveCollections(mstMapId: string) {
            if (!self.collections.get(mstMapId)) {
                self.fetchCollectionCards({ urltag: mstMapId });
            }
        },
        retrieveLibraryInfo() {
            if (isEqual(self.libraryPricing, {})) {
                self.fetchAllLibraryInfo();
            }
        },
    }))
    .views((self) => ({
        getCollections(mstMapId: string) {
            const selectedCollection = self.collections.get(mstMapId);

            if (!self.additionalInfo) {
                return selectedCollection;
            }

            const tagTest = (tag: string, tagToTest: string) => {
                const response: Record<string, string> = {
                    new_release_ids: 'new',
                    boom_exclusive_ids: 'exclusive',
                };
                return self.additionalInfo?.[tag]?.indexOf(tagToTest) !== -1
                    ? response?.[tag]
                    : null;
            };
            const selectedCollectionLabeled = map(
                selectedCollection,
                (coll: TypeLibraryCollectionModel) => ({
                    ...coll,
                    tags: [
                        tagTest('new_release_ids', coll.tag),
                        tagTest('boom_exclusive_ids', coll.tag),
                    ].filter((x) => x),
                    isNew: self.additionalInfo?.new_release_ids?.indexOf(coll.tag) !== -1,
                    isExclusive: self.additionalInfo?.boom_exclusive_ids?.indexOf(coll.tag) !== -1,
                })
            );
            /*
              If we have multiple tags to display, this is the order in which they should appear
              New / Exclusive
              New
              Exclusive
            */
            const selectedCollectionNewExclusive = sortBy(
                filter(selectedCollectionLabeled, (s) => s.isNew && s.isExclusive),
                ['tag']
            );
            const selectedCollectionNew = sortBy(
                filter(selectedCollectionLabeled, (s) => s.isNew && !s.isExclusive),
                ['tag']
            );
            const selectedCollectionExclusive = sortBy(
                filter(selectedCollectionLabeled, (s) => !s.isNew && s.isExclusive),
                ['tag']
            );
            const selectedCollectionOld = sortBy(
                filter(selectedCollectionLabeled, (s) => !s.isNew && !s.isExclusive),
                ['tag']
            );

            return [
                ...selectedCollectionNewExclusive,
                ...selectedCollectionNew,
                ...selectedCollectionExclusive,
                ...selectedCollectionOld,
            ];
        },
        getFilteredCollections(searchTerm: string, mstMapId: string) {
            if (self.collections.get(mstMapId) === undefined) {
                return;
            }
            const collectionsMetadata = map(self.collections.get(mstMapId), (coll) => ({
                id: coll.tag,
                metadata: coll?.metadata
                    ?.toLowerCase()
                    .split(',')
                    .filter((i: any) => i),
                descriptions: coll?.description?.toLowerCase(),
                title: coll?.title?.toLowerCase(),
            }));
            const partialInclude = (termList: string[], searchTerm: string) =>
                reduce(
                    termList,
                    (result, term) => {
                        const isSubstring = term.indexOf(searchTerm) !== -1;
                        const isExactMatch = term === searchTerm;
                        return result || isExactMatch || isSubstring;
                    },
                    false
                );

            const searchTermInLowercase = searchTerm.toLowerCase().trim();
            const exactMatch = mapKeys(
                filter(collectionsMetadata, (coll) => {
                    const foundInDescription =
                        coll?.descriptions?.indexOf(searchTermInLowercase) !== -1;
                    const foundInTitle = coll.title.indexOf(searchTermInLowercase) !== -1;
                    const foundInMetadata = includes(coll.metadata, searchTermInLowercase);
                    return foundInDescription || foundInTitle || foundInMetadata;
                }),
                (coll) => coll.id
            );

            const partialMatch = mapKeys(
                filter(collectionsMetadata, (coll) =>
                    reduce(
                        searchTermInLowercase.split(' ').filter((element) => element),
                        (result: boolean, term: string) => {
                            const collectionFound =
                                result ||
                                partialInclude(coll.metadata, term) ||
                                partialInclude(coll.descriptions?.split(' '), term);
                            const excludeBecauseInExact = includes(keys(exactMatch), coll.id);
                            return collectionFound && !excludeBecauseInExact;
                        },
                        false
                    )
                ),
                (coll) => coll.id
            );
            return {
                partialMatch,
                exactMatch,
                searchTerm,
            };
        },
        getLibraryInfo(mstMapId: string) {
            const testing1 = self.libraryInfo.get(mstMapId);
            const testing2 = self.libraryPricing;
            console.log('LOOK HERE (src)');
            console.log('[TEST] - mstMapId :  ', mstMapId);
            console.log('[TEST] - testing1 :  ', testing1);
            console.log('[TEST] - testing2 :  ', testing2);

            return self.libraryInfo.get(mstMapId);
        },
    }));

export type TypeStrataLibraryCardStore = Instance<typeof AkLibraryStore>;
export type TaggedMetadata = {
    id: string;
    metadata: string[];
    descriptions: string;
    title: string;
};

export type SearchTermResults = {
    partialMatch: Record<string, TaggedMetadata>;
    exactMatch: Record<string, TaggedMetadata>;
    searchTerm: string | undefined;
};

const AkLibraryStoreInstance = AkLibraryStore.create();
if (window && isDev()) {
    (window as any).AkLibraryStore = AkLibraryStoreInstance;
}

export default AkLibraryStore;
