import { Instance, types } from 'mobx-state-tree';

const PlatformModel = types.model('PlatformModel', {
    platformId: 0,
    platformName: '',
    iconUrl: '',
    position: 0,
    platformTag: '',
    platformGroups: types.maybeNull(types.array(types.string)),
});

export type TypePlatformModel = Instance<typeof PlatformModel>;

export default PlatformModel;
