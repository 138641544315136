/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';
import { mapKeys } from 'lodash';

import { createApiAction } from '../../api/api-action-factory';
import { TypedStoreConfig } from '../factories/store';

import { PLATFORMS_ENDPOINT } from '../../api/endpoints';

import PlatformModel from './models/platform-model';

import { isDev } from '../../utils/build-utils';
import { lowerSnakeCase, stripNonAlphaNumeric } from '../../utils/utils';

const PlatformStore = TypedStoreConfig({
    modelName: 'PlatformStore',
    properties: {
        isLoading: false,
        platforms: types.array(PlatformModel),
    },
})
    .actions((self) => ({
        getPlatforms: createApiAction(self, {
            endpoint: PLATFORMS_ENDPOINT,
            target: 'platforms',
        }),
    }))
    .actions((self) => ({
        retrievePlatforms() {
            if (!self.platforms.length) {
                self.getPlatforms();
            }
        },
    }))
    .views((self) => ({
        supportedPlatforms() {
            self.retrievePlatforms();
            return self.platforms?.filter((platforms) => platforms.iconUrl !== null);
        },
        get localizedPlatformsTags() {
            const localizedPlatformsTags: Record<string, string> = {};

            self.platforms
                ?.filter((platforms) => platforms.iconUrl !== null)
                .forEach((itm: any) => {
                    localizedPlatformsTags[itm.platformId] = itm.platformName;
                });

            return localizedPlatformsTags;
        },
        get platformsTags() {
            return mapKeys(
                self.platforms
                    ?.filter((platforms) => platforms.iconUrl !== null)
                    .map((itm) => ({
                        ...itm,
                        label: itm.platformName,
                        fe_id: lowerSnakeCase(stripNonAlphaNumeric(itm.platformName)),
                        be_id: itm.platformId,
                    })),
                (itm) => itm.fe_id
            );
        },
    }));

export type TypePlatformStore = Instance<typeof PlatformStore>;

const PlatformStoreInstance = PlatformStore.create({});
declare const window: Window &
    typeof globalThis & {
        PlatformStore: typeof PlatformStoreInstance;
    };
if (window && isDev()) {
    window.PlatformStore = PlatformStoreInstance;
}

export default PlatformStore;
