import { lazy } from 'react';

import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/profile';

export const ROUTES = typedRoutes({
    PROFILE: {
        ...PATHS.PROFILE,
        id: 'PROFILE',
        name: 'Profile',
        i18nId: 'profile:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/profile/profile')),
    },
    PROFILE_CREATE_THANK_YOU: {
        ...PATHS.PROFILE_CREATE_THANK_YOU,
        id: 'PROFILE_CREATE_THANK_YOU',
        name: 'Profile Create Thank You',
        i18nId: 'profile:profile-management.create-profile-thank-you',
        displayInMenu: false,
        privateMode: true,
        component: lazy(
            () =>
                import(
                    '../components/modules/profile/create-profile-thank-you/create-profile-thank-you'
                )
        ),
    },
    PUBLIC_PROFILE: {
        ...PATHS.PUBLIC_PROFILE,
        id: 'PUBLIC_PROFILE',
        name: 'Settings',
        i18nId: 'profile:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/profile/profile-public-preview')),
    },
    CONFIRM_NEW_ACCOUNT: {
        ...PATHS.CONFIRM_NEW_ACCOUNT,
        id: 'CONFIRM_NEW_ACCOUNT',
        name: 'Profile Basic Information',
        i18nId: 'profile:profile-management.profile-basic-information',
        displayInMenu: false,
        component: lazy(
            () => import('../components/modules/profile/account-confirmation/basic-information')
        ),
    },
    PROFILE_NEW_PASSWORD: {
        ...PATHS.PROFILE_NEW_PASSWORD,
        id: 'PROFILE_NEW_PASSWORD',
        name: 'Profile New Password',
        i18nId: 'profile:profile-management.profile-new-password',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/profile/new-password/new-password')),
    },
    PROFILE_NEW_EMAIL: {
        ...PATHS.PROFILE_NEW_EMAIL,
        id: 'PROFILE_NEW_EMAIL',
        name: 'Profile New Email',
        i18nId: 'profile:profile-management.profile-new-email',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/profile/new-email/new-email')),
    },
    SIGN_IN: {
        ...PATHS.SIGN_IN,
        id: 'SIGN_IN',
        name: 'Sign In',
        i18nId: 'profile:profile-management.profile-new-email',
        displayInMenu: false,
        component: lazy(() => import('../components/authentication/sign-in-up/inline-sign-in-up')),
    },
    PROFILE_COMPANY: {
        ...PATHS.PROFILE_COMPANY,
        id: 'PROFILE_COMPANY',
        name: 'Profile Company',
        i18nId: 'profile:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/profile-entity/profile-entity')),
    },
    PROFILE_COMPANY_PUBLIC: {
        ...PATHS.PROFILE_COMPANY_PUBLIC,
        id: 'PROFILE_COMPANY_PUBLIC',
        name: 'Settings',
        i18nId: 'profile:module.menu-label',
        displayInMenu: false,
        component: lazy(
            () => import('../components/modules/profile-entity/profile-content-provider-public')
        ),
    },
    PROFILE_SCHOOL: {
        ...PATHS.PROFILE_SCHOOL,
        id: 'PROFILE_SCHOOL',
        name: 'Profile School',
        i18nId: 'profile:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/profile-entity/profile-entity')),
    },
    PROFILE_SCHOOL_PUBLIC: {
        ...PATHS.PROFILE_SCHOOL_PUBLIC,
        id: 'PROFILE_SCHOOL_PUBLIC',
        name: 'Settings',
        i18nId: 'profile:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/profile-entity/profile-school-public')),
    },
});

export const DEFAULT_ROUTE = ROUTES.PROFILE;
