import { lazy } from 'react';
import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/solutions';

export const ROUTES = typedRoutes({
    SOLUTIONS: {
        ...PATHS.SOLUTIONS,
        id: 'SOLUTIONS',
        name: 'Solutions',
        i18nId: 'solutions:module.solutions.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/solutions/solutions')),
    },
    GAMING: {
        ...PATHS.GAMING,
        id: 'GAMING',
        name: 'Gaming',
        i18nId: 'solutions:module.gaming.menu-label',
        component: lazy(() => import('../components/modules/solutions/gaming/gaming')),
    },
    AUTOMOTIVE: {
        ...PATHS.AUTOMOTIVE,
        id: 'AUTOMOTIVE',
        name: 'Automotive',
        i18nId: 'solutions:module.automotive.menu-label',
        component: lazy(() => import('../components/modules/solutions/automotive/automotive')),
    },
    LBE: {
        ...PATHS.LBE,
        id: 'LBE',
        name: 'LocationBasedEntertainment',
        i18nId: 'solutions:module.lbe.menu-label',
        component: lazy(
            () =>
                import(
                    '../components/modules/solutions/location-based-entertainment/location-based-entertainment'
                )
        ),
    },
    SIMULATION: {
        ...PATHS.SIMULATION,
        id: 'SIMULATION',
        name: 'Simulation',
        i18nId: 'solutions:module.simulation.menu-label',
        component: lazy(() => import('../components/modules/solutions/simulation/simulation')),
    },
    CONSUMER_ELECTRONICS: {
        ...PATHS.CONSUMER_ELECTRONICS,
        id: 'CONSUMER_ELECTRONICS',
        name: 'Consumer Electronics',
        i18nId: 'solutions:module.consumer-electronics.menu-label',
        component: lazy(
            () =>
                import('../components/modules/solutions/consumer-electronics/consumer-electronics')
        ),
    },
    CREATIVE_SERVICES: {
        ...PATHS.CREATIVE_SERVICES,
        id: 'CREATIVE_SERVICES',
        name: 'CreativeServices',
        i18nId: 'products:module.creative-services.menu-label',
    },
    DISCOVER: {
        ...PATHS.DISCOVER,
        id: 'DISCOVER',
        name: 'Discover',
        displayInMenu: false,
        i18nId: 'solutions:module.solutions.menu-label',
    },
    DISCOVER_LBE: {
        ...PATHS.DISCOVER_LBE,
        id: 'DISCOVER_LBE',
        name: 'Discover LocationBasedEntertainment',
        displayInMenu: false,
        i18nId: 'solutions:module.solutions.menu-label',
    },
});

export const DEFAULT_ROUTE = ROUTES.SOLUTIONS;
