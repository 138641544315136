import { Instance, types } from 'mobx-state-tree';

const NotificationModel = types.model('NotificationsModel', {
    checkboxLabel: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    value: types.maybeNull(types.boolean),
    ordinal: types.maybeNull(types.number),
});

const NotificationsModel = types.model('NotificationsModel', {
    emailSubscriptions: types.model({
        AKTAG_STRATA_NEWSLETTER_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_BLOG_EN_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_BLOG_FR_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_BLOG_JA_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_BLOG_KO_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_BLOG_ZH_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_CERTIFICATION_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_EDUCATION_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_EVENTS_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_MEDIA_NAME: NotificationModel,
        AKTAG_SUBSCRIPTIONS_RELEASE_NAME: NotificationModel,
    }),
    systemNotifications: types.model({
        AKTAG_SYSTEM_NOTIFICATIONS: NotificationModel,
    }),
});

export type TypeNotificationsModel = Instance<typeof NotificationsModel>;
export default NotificationsModel;
