import { lazy } from 'react';
import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/thank-you';

export const ROUTES = typedRoutes({
    THANK_YOU: {
        ...PATHS.THANK_YOU,
        id: 'THANK_YOU',
        name: 'THANK_YOU',
        i18nId: 'general:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/thank-you/thank-you')),
    },
    WWISE_LAUNCHER: {
        ...PATHS.WWISE_LAUNCHER,
        id: 'WWISE_LAUNCHER',
        name: 'WWISE_LAUNCHER',
        i18nId: 'general:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/thank-you/thank-you')),
    },
    LAUNCHER_PROJECTS: {
        ...PATHS.LAUNCHER_PROJECTS,
        id: 'LAUNCHER_PROJECTS',
        name: 'LAUNCHER_PROJECTS',
        i18nId: 'general:module.menu-label',
        displayInMenu: false,
    },
});

export const DEFAULT_ROUTE = ROUTES.THANK_YOU;
