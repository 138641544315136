import { Instance, types } from 'mobx-state-tree';

const EmployeeModel = types.model('EmployeeModel', {
    userId: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    jobTitle: types.maybeNull(types.string),
    avatarImage: types.maybeNull(types.string),
    certifications: types.array(types.string),
});
export type TypeEmployeeModel = Instance<typeof EmployeeModel>;

const LicenseAgreementLinkModel = types.model('LicenseAgreementLinkModel', {
    document_id: types.number,
    filename: types.string,
    label: types.string,
    signed: types.boolean,
    url: types.string,
});
export type TypeLicenseAgreementLinkModel = Instance<typeof LicenseAgreementLinkModel>;

const LicenseAgreementsModel = types.model('LicenseAgreementsModel', {
    links: types.array(LicenseAgreementLinkModel),
    title: types.string,
    jobTitle: types.maybeNull(types.string),
    avatarImage: types.maybeNull(types.string),
    certifications: types.array(types.string),
});
export type TypeLicenseAgreementsModel = Instance<typeof LicenseAgreementsModel>;

const CourseModel = types.model('CourseModel', {
    code: types.string,
    name: types.string,
    description: types.maybe(types.string),
    mask: types.string,
    languages: types.array(types.string),
    url: types.maybeNull(types.string),
    accessStatus: types.maybeNull(types.boolean),
    ctas: types.frozen({}),
    academicSession: types.maybe(types.string),
    startDate: types.maybe(types.string),
    endDate: types.maybe(types.string),
    courseDescription: types.maybe(types.string),
    licenseAgreements: types.maybe(LicenseAgreementsModel),
});
export type TypeCourseModel = Instance<typeof CourseModel>;

const ServicesModel = types.model('ServicesModel', {
    primary: types.array(types.string),
    secondary: types.array(types.string),
});
export type TypeServicesModel = Instance<typeof ServicesModel>;

export const ProfileEntityDetailsStore = types.model('ProfileEntityDetailsStore', {
    description: types.maybeNull(types.string),
    employees: types.array(EmployeeModel),
    languages: types.array(types.string),
    locationSummary: types.maybeNull(types.string),
    logoUrl: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    numberCertifiedUsers: types.maybe(types.number),
    numberRegisteredUsers: types.maybe(types.number),
    profileUrl: types.maybeNull(types.string),
    services: types.maybe(ServicesModel),
    website: types.maybeNull(types.string),
});
export type TypeProfileEntityDetailsStore = Instance<typeof ProfileEntityDetailsStore>;

export const ProfileContentProviderDetailsStore = types.compose(
    'ProfileContentProviderDetailsStore',
    ProfileEntityDetailsStore,
    types.model({
        organizationId: types.maybe(types.number),
        isAmbassador: types.maybe(types.boolean),
        projectsCount: types.maybe(types.number),
        regionCode: types.maybeNull(types.string),
        countryCode: types.maybeNull(types.string),
    })
);
export type TypeProfileContentProviderDetailsStore = Instance<
    typeof ProfileContentProviderDetailsStore
>;

export const ProfileSchoolDetailsStore = types.compose(
    'ProfileSchoolDetailsStore',
    ProfileEntityDetailsStore,
    types.model({
        schoolId: types.maybe(types.number),
        regionName: types.maybeNull(types.string),
        countryName: types.maybeNull(types.string),
        courses: types.array(CourseModel),
    })
);
export type TypeProfileSchoolDetailsStore = Instance<typeof ProfileSchoolDetailsStore>;

export default ProfileEntityDetailsStore;
