module.exports = {
    LOCAL_SERVER_REPLACE_LOOKUP: {
        'localhost:3000': 'https://www-qa.hq.audiokinetic.com',
        'www-localhost.audiokinetic.inte': 'https://www-qa.hq.audiokinetic.com',
        'www-localhost.gowwise.com': 'https://paul.gowwise.com',
        dev: 'https://dev.gowwise.com',
        paul: 'https://paul.gowwise.com',
        francis: 'https://francis.gowwise.com',
        kierk: 'https://kierk.gowwise.com',
        jo: 'https://jonathan.gowwise.com',
        aj: 'https://aj.gowwise.com',
    },
};
