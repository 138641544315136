import { lazy } from 'react';

import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/licenses-subscriptions';

export const ROUTES = typedRoutes({
    LICENSES_SUBSCRIPTIONS: {
        ...PATHS.LICENSES_SUBSCRIPTIONS,
        id: 'LICENSES_SUBSCRIPTIONS',
        name: 'Licenses & Subscriptions',
        i18nId: 'licenses_subscriptions:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(
            () => import('../components/modules/licenses-subscription/licenses-subscription')
        ),
    },
    LICENSES_SUBSCRIPTIONS_WITH_SECTION: {
        ...PATHS.LICENSES_SUBSCRIPTIONS_WITH_SECTION,
        id: 'LICENSES_SUBSCRIPTIONS_WITH_SECTION',
        name: 'Licenses & Subscriptions',
        i18nId: 'licenses_subscriptions:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(
            () => import('../components/modules/licenses-subscription/licenses-subscription')
        ),
    },
});

export const DEFAULT_ROUTE = ROUTES.LICENSES_SUBSCRIPTIONS;
