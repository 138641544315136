import React from 'react';
import { inject, observer } from 'mobx-react';

export function injectStores<StoreName extends string>(stores: StoreName[]) {
    return <T extends React.ComponentType<React.ComponentProps<T>>>(Component: T) =>
        stores.reduce(
            (wrappedFunc, store) => inject(store)(observer(wrappedFunc)),
            Component as React.ComponentType<Omit<React.ComponentProps<T>, StoreName>>
        );
}
