import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/search';

export const ROUTES = typedRoutes({
    SEARCH: {
        ...PATHS.SEARCH,
        id: 'SEARCH',
        name: 'SEARCH',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
});

export const DEFAULT_ROUTE = ROUTES.SEARCH;
